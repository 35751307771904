import {
    GET_PENDING_FORMS,
    GET_PENDING_REFUNDS,
    GET_PRINTED_FORMS,
    GET_SCANNED_FORMS,
    GET_WIN_FORMS,
    SERVER_URL,
    GET_FORM_TO_VALIDATE,
    SET_FORM_VALIDATE_STATE,
    GET_ALL_BATCH,
    SET_BATCH_INVALIDATE_STATE, GET_ALL_FORMS, GET_IS_MANUAL,
} from "./configurations";
import {HEBREW_DATE_AND_TIME, HEBREW_GAME, HEBREW_PULL_STATUS, HEBREW_SUM_IN_SHEK, ID} from "../consts";
import {handleErrors} from "./utils";

const HEBREW_USER_NAME = 'שם משתמש'
const HEBREW_USER_ID = 'תעודת זהות'
const HEBREW_LOTTERY_NUMBER = 'מספר הגרלה'
const HEBREW_FORM_NUMBER = 'מספר טופס'
const HEBREW_AGENT = 'סוכן'
export type PendingFormsRows = {
    [HEBREW_USER_NAME]: string,
    [HEBREW_USER_ID]: number,
    [HEBREW_LOTTERY_NUMBER]: number,
    [HEBREW_GAME]: string
    [HEBREW_FORM_NUMBER]: string
    [HEBREW_AGENT]: string,
    [ID]: string,
    'scan_status'?:string,
    'batch_id'?:string,
    'status'?: string,
}[]

export type RefundRequestsHeadersType = ['id', 'שם משתמש', 'תעודת זהות', 'סה"כ בשקלים', 'תאריך ושעה', 'סטטוס משיכה','אישור' ]
export type RefundRequestsRows = {
    [ID]: string,
    [HEBREW_USER_NAME]: string,
    [HEBREW_USER_ID]: number
    [HEBREW_SUM_IN_SHEK]: number,
    [HEBREW_DATE_AND_TIME]: string,
    [HEBREW_PULL_STATUS]: string,
    trn_id: string
}[]
export type RefundRequestsType = {
    headers: RefundRequestsHeadersType,
    rows: RefundRequestsRows,
    cur_page: number,
    total: number,
    has_next: boolean,
    has_prev: boolean,
}
export type AdminFromsType = {
    headers: ['שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן', 'תאריך'],
    rows: PendingFormsRows
}

export type IsManualType = {
    is_manual: boolean
}

export type AdminFromsTypePagination = {
    headers: RefundRequestsHeadersType,
    rows: RefundRequestsRows,
    cur_page: number,
    total: number,
    has_next: boolean,
    has_prev: boolean,
}

export const getPendingRequests = async (jwtToken: string, page:number, per_page:number): Promise<AdminFromsTypePagination> => {
    return await handleErrors(fetch(`${GET_PENDING_REFUNDS.href}/${page}/${per_page}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        }
    }))
};

export const getAllBatches = async (jwtToken: string, page:number, per_page:number, providerId: number): Promise<AdminFromsTypePagination> => {
    return await handleErrors(fetch(`${GET_ALL_BATCH.href}/${page}/${per_page}/${providerId}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        }
    }))
};

export const getUsersPendingForms = async (jwtToken: string, providerId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_PENDING_FORMS.href}/${providerId}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getUsersPrintedForms = async (jwtToken: string, providerId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_PRINTED_FORMS.href}/${providerId}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getFormToValidate = async (jwtToken: string, id: string): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_FORM_TO_VALIDATE.href}/${id}`, {
        method: 'GET',
        headers: {
            Authorization: jwtToken,
        }
    }))
};

export const setFormValidateState = async (jwtToken: string, id: number, status:boolean): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${SET_FORM_VALIDATE_STATE.href}/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
        },
        body: JSON.stringify({status}),
    }))
};
export const setInalidateBatchState = async (jwtToken: string, batchId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${SET_BATCH_INVALIDATE_STATE.href}/${batchId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
        },
        body: JSON.stringify({batchId}),
    }))
};

export const getUsersWinForms = async (jwtToken: string, providerId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_WIN_FORMS.href}/${providerId}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getIfIsManual = async (jwtToken : string): Promise<IsManualType> => {
    return await handleErrors(fetch(GET_IS_MANUAL.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
}

export const setIsManualReq = async (jwtToken : string, is_manual: boolean): Promise<IsManualType> => {
    return await handleErrors(fetch(GET_IS_MANUAL.href, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({is_manual})
    }))
}

export const jumpNextLottoNumber = async (): Promise<void> => {
    return await handleErrors(fetch('https://lottomatic-be-74805505622.me-west1.run.app/autoSender/updateLottoNextNumber', {
        method: 'GET',
    }))
}

export const getAllForms = async (jwtToken: string, providerId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_ALL_FORMS.href}/${providerId}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getUsersScannedForms = async (jwtToken: string, providerId: number): Promise<AdminFromsType> => {
    return await handleErrors(fetch(`${GET_SCANNED_FORMS.href}/${providerId}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const changeStatusToPrinted = async (formId: string, jwtToken: string): Promise<void> => {
    const CHANGE_TO_PRINT_PATH = new URL(`/admin/forms/change_to_printed/${formId}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_TO_PRINT_PATH.href, {
        method: 'PUT', headers: {
            Authorization: jwtToken
        }
    }))
};
export const changeStatusDecliend = async (trn_id: string, rtn_value:string, jwtToken: string): Promise<void> => {
    const CHANGE_STATUS_DECLIEND_PATH = new URL(`/admin/decline_refund/${trn_id}/${rtn_value}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_STATUS_DECLIEND_PATH.href, {
        method: 'PUT',
        headers: {
            Authorization: jwtToken
        }
    }))
};
export const changeStatusApproved = async (trn_id: string, rtn_value:string, jwtToken: string): Promise<void> => {
    const CHANGE_STATUS_APPROVED_PATH = new URL(`/admin/approve_refund/${trn_id}/${rtn_value}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_STATUS_APPROVED_PATH.href, {
        method: 'PUT',
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const changeStatusToCharged = async (formId: string, jwtToken: string): Promise<void> => {
    const CHANGE_TO_CHARGED_PATH = new URL(`/admin/forms/change_to_charged/${formId}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_TO_CHARGED_PATH.href, {
        method: 'PUT', headers: {
            Authorization: jwtToken
        }
    }))
};


export const changeStatusToScanned = async (formId: string, jwtToken: string): Promise<any> => {
    const CHANGE_TO_SCANNED_PATH = new URL(`/admin/forms/change_to_scanned/${formId}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_TO_SCANNED_PATH.href, {
        method: 'PUT', headers: {
            Authorization: jwtToken
        }
    }))
};

export const changeStatusToFinish = async (formId: string, jwtToken: string): Promise<void> => {
    const CHANGE_TO_FINISH_PATH = new URL(`/admin/forms/change_to_finish/${formId}`, SERVER_URL)
    return await handleErrors(fetch(CHANGE_TO_FINISH_PATH.href, {
        method: 'PUT', headers: {
            Authorization: jwtToken
        }
    }))
};

export const addLotteryPrize = async (data: any, jwtToken: string): Promise<void> => {
    const UPLOAD_LOTTERY = new URL('/admin/add_lottery_prize', SERVER_URL)
    return await handleErrors(fetch(UPLOAD_LOTTERY.href, {
        method: 'POST', headers: {
            Authorization: jwtToken
        }, body: JSON.stringify(data)
    }))
}

export const deleteLotteryPrize = async (data: any, jwtToken: string): Promise<void> => {
    const UPLOAD_LOTTERY = new URL('/admin/delete_lottery_prize', SERVER_URL)
    return await handleErrors(fetch(UPLOAD_LOTTERY.href, {
        method: 'POST', headers: {
            Authorization: jwtToken
        }, body: JSON.stringify(data)
    }))
}

export const addLottery = async (data: any, jwtToken: string): Promise<void> => {
    const UPLOAD_LOTTERY = new URL('/admin/start_lottery', SERVER_URL)
    return await handleErrors(fetch(UPLOAD_LOTTERY.href, {
        method: 'POST', headers: {
            Authorization: jwtToken
        }, body: JSON.stringify(data)
    }))
}

export const deleteLottery = async (data: any, jwtToken: string): Promise<void> => {
    const UPLOAD_LOTTERY = new URL('/admin/end_lottery', SERVER_URL)
    return await handleErrors(fetch(UPLOAD_LOTTERY.href, {
        method: 'POST', headers: {
            Authorization: jwtToken
        }, body: JSON.stringify(data)
    }))
}

export const addAgent = async (name: any, jwtToken: string): Promise<void> => {
    const ADD_AGENT_PATH = new URL('/admin/agent', SERVER_URL)
    return await handleErrors(fetch(ADD_AGENT_PATH.href, {
        method: 'POST',
        headers: {
            Authorization: jwtToken
        }, body: JSON.stringify({name})
    }))
}

export const getAgents = async (jwtToken: string): Promise<void> => {
    const ADD_AGENT_PATH = new URL('/admin/agent', SERVER_URL)
    return await handleErrors(fetch(ADD_AGENT_PATH.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
}
